import { createSlice } from '@reduxjs/toolkit';

export interface ProjectSelectedState {
  project: string | null;
  selectedProjectCountry: string | null;
  showWarningDialog: boolean;
}

const initialState: ProjectSelectedState = {
  project: localStorage.getItem('selectedProject') || null,
  selectedProjectCountry: localStorage.getItem('selectedProjectCountry')
    ? JSON.parse(localStorage.getItem('selectedProjectCountry') || 'null')
    : null,
  showWarningDialog: false
};

export const projcetSelectedSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    SET_PROJECT: (state, action) => {
      state.project = action.payload;
      localStorage.setItem('selectedProject', action.payload);
    },
    SET_PROJECT_COUNTRY: (state, action) => {
      state.selectedProjectCountry = action.payload;
      localStorage.setItem('selectedProjectCountry', JSON.stringify(action.payload));
    },
    SET_WARNING_DIALOG: (state, action) => {
      state.showWarningDialog = action.payload;
    }
  }
});

export const { SET_PROJECT, SET_PROJECT_COUNTRY, SET_WARNING_DIALOG } = projcetSelectedSlice.actions;
export default projcetSelectedSlice.reducer;
