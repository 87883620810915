// MaterialUI
import { Grid, IconButton, Menu, Stack, Tooltip, Typography } from '@mui/material';
// Custom Components
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import Loader from '@/components/BlurLoader';
import { useNotifyGetQuery, usePostNotificationMutation } from '@/store/api/general/api';
import { NotificationResponse } from '@/store/api/general/dto';

import NotificationIcon from '../../assets/navbar/notification_icon.svg';
import NotificationNotSeenIcon from '../../assets/navbar/notify_not_seen.svg';
import NotifyInfoIcon from '../../assets/notification/icons/notify_icon_info.svg';

interface INotificationMenu {
  allNotificationItems?: React.ReactNode;
  importantNotificationItems?: React.ReactNode;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const SortByMenuStyle = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 5,
    ml: -80,
    '&:before': {
      content: '""',
      display: 'none',
      position: 'absolute',
      top: 0,
      left: '307px',
      width: '18px',
      height: '19px',
      backgroundColor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 1
    },
    '& .MuiList-root': {
      position: 'absolute',
      width: '620px !important',
      padding: 0,
      backgroundColor: 'background.paper',
      borderRadius: '10px'
    },
    '& .MuiMenuItem-root': {
      padding: '11px 37px 11px 19px'
    }
  }
};

// TabPanel component
function TabPanel(props: TabPanelProps): JSX.Element {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const NotificationMenu = (props: INotificationMenu): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isPost, setIsPost] = useState(false);
  const [postNotification] = usePostNotificationMutation();

  const handleClick = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    setAnchorEl(event.currentTarget);
    if (!isPost) {
      await postNotification().unwrap();
      setIsPost(true);
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const [value, setValue] = useState(0);
  const [take] = useState(20); // Number of items per page
  const [skip, setSkip] = useState(0); // Track current offset
  const [notifications, setNotifications] = useState<NotificationResponse['data']>([]); // Holds all loaded notifications
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0); // To track total notifications count

  const { data, isLoading, isFetching } = useNotifyGetQuery({ take, skip });

  useEffect(() => {
    if (data && data.data) {
      setNotifications((prevNotifications) => [...prevNotifications, ...data.data]);
      setTotalCount(data.totalCount); // Save totalCount from response
    }
  }, [data]);

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  const loadMoreNotifications = (): void => {
    if (!loading && skip < totalCount) {
      // Check if we have more notifications to load
      setLoading(true);
      setSkip((prevSkip) => prevSkip + take);
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Bildirimler" placement="bottom">
        <IconButton
          disableRipple
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, borderRadius: '6px' }}
          aria-controls={open ? 'fold-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <img
            src={!isPost ? (data?.data && data?.data?.length > 0 ? NotificationIcon : NotificationNotSeenIcon) : NotificationNotSeenIcon}
            style={{ width: 40 }}
            alt="Notification Icon"
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="fold-menu"
        open={open}
        onClose={handleClose}
        PaperProps={SortByMenuStyle}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
      >
        <Stack>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            padding="20px 16px 16px 32px"
            borderRadius="10px 10px 0px 0px"
            bgcolor="black"
          >
            <Typography variant="h5" color="primary" sx={{ color: 'white' }}>
              Bildirimler
            </Typography>
          </Stack>
          <Box sx={{ width: '100%' }}>
            <Box boxShadow="3px 3px 3px #00000029">
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  paddingLeft: '32px'
                }}
              >
                <Tab
                  style={{
                    padding: '0px',
                    marginRight: '32px',
                    alignItems: 'start'
                  }}
                  label={
                    <Typography fontWeight="600" color="white">
                      Hepsi
                    </Typography>
                  }
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                onScroll={(e) => {
                  const target = e.currentTarget;
                  const scrollThreshold = 5; // 5 pixel tolerance

                  if (target.scrollTop + target.clientHeight >= target.scrollHeight - scrollThreshold) {
                    loadMoreNotifications();
                  }
                }}
                maxHeight={400}
                style={{ overflow: 'auto', position: 'relative', margin: -15, paddingLeft: 15, paddingRight: 15 }}
              >
                {notifications.map((item, index) => (
                  <Grid container rowSpacing={1} sx={{ py: 6, borderBottom: '1px solid #FFD100' }} key={index}>
                    <Grid xs={10} sx={{ display: 'flex', alignItems: 'start' }}>
                      <img src={NotifyInfoIcon} style={{ marginTop: '10px', width: 40, marginRight: '20px', marginLeft: '10px' }} />
                      <Stack>
                        <Typography variant="body2">{item.title}</Typography>
                        <Typography variant="body1">{item.body}</Typography>
                      </Stack>
                    </Grid>
                    <Grid xs={2} display="flex" justifyContent="space-between" flexDirection="column" alignItems="end">
                      <Stack sx={{ width: '10px', height: '10px', bgcolor: '#FFD100', borderRadius: '100%' }} />
                      <small>{dayjs(item.updatedAt).format('DD.MM.YYYY HH:mm')}</small>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
              {(isFetching || isLoading) && <Loader />}
            </TabPanel>
          </Box>
        </Stack>
      </Menu>
    </>
  );
};

export default NotificationMenu;
