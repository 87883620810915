import { Avatar, Button, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { default as CancelIcon } from '@/assets/shared/cancel_icon.svg';
import { default as PhoneIcon } from '@/assets/shared/phone_icon.svg';
import { default as LockIcon } from '@/assets/userProfile/lock_icon.svg';
import { default as MailIcon } from '@/assets/userProfile/mail_icon.svg';
import { default as PersonIcon } from '@/assets/userProfile/person_icon.svg';
import PhoneView from '@/components/PhoneView';
import { LOGGED_OUT } from '@/page/Login/reducer/auth-slice';

//import Button from "@mui/material/Button";
import { useGetUserProfileQuery } from '../../../store/api/auth/api';
import { setPageMode } from './reducer/slice';

interface IProfile {
  changeDrawerStatus: (newDrawerStatus: boolean) => void;
  drawerStatus: boolean;
}

const ProfileView = (props: IProfile) => {
  const { changeDrawerStatus, drawerStatus } = props;

  const dispatch = useDispatch();

  const { data: userProfile } = useGetUserProfileQuery();

  const toggleDrawer = () => {
    changeDrawerStatus(!drawerStatus);
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="13px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <img width={25} src={PersonIcon} alt="PersonIcon" />
            <Typography variant="h6">Profil</Typography>{' '}
          </Stack>
          <IconButton disableRipple onClick={toggleDrawer}>
            <img src={CancelIcon} alt="Logout Icon" />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" marginBottom="4px" alignItems="center">
          <Avatar sx={{ width: '70px', height: '70px' }} />
          <Stack>
            <Button
              sx={{
                marginLeft: '12px',
                maxWidth: '160px'
              }}
            >
              <Typography sx={{ opacity: '60%' }} variant="h6">
                Değiştir
              </Typography>{' '}
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ marginTop: '32px', marginBottom: '10px' }} />
        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-start'}>
          <Button
            variant="text"
            sx={{
              maxWidth: '90px'
            }}
            onClick={() => dispatch(setPageMode('profileEdit'))}
          >
            <Typography sx={{ opacity: '60%' }} variant="h6">
              Düzenle
            </Typography>{' '}
          </Button>
        </Stack>
        <Stack
          direction="row"
          // justifyContent="space-between"
          pt="14px"
        >
          <Grid item xs={5}>
            <Typography>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1 }}>
                <img src={PersonIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              Ad Soyad
            </Typography>
          </Grid>
          <Grid pt={1}>
            <Typography variant="h6"> {userProfile?.name}</Typography>
          </Grid>
        </Stack>
        <Stack direction="row" pt="20px">
          <Grid item xs={5}>
            <Typography>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1 }}>
                <img src={MailIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              E-posta Adresi
            </Typography>
          </Grid>
          <Grid pt={1}>
            <Typography variant="h6">{userProfile?.email}</Typography>
          </Grid>
        </Stack>
        <Stack direction="row" pt="20px">
          <Grid item xs={5}>
            <Typography>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1 }}>
                <img src={PhoneIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              Telefon Numarası
            </Typography>
          </Grid>
          <Grid pt={1}>
            <PhoneView value={userProfile?.phone} />
          </Grid>
        </Stack>
        <Divider sx={{ marginTop: '32px', marginBottom: '16px' }} />
        <Stack direction="row" justifyContent="start" alignItems="center">
          <Grid item xs={4}>
            <Button variant="text" onClick={() => dispatch(setPageMode('passwordChange'))} disableRipple>
              <IconButton size="small" disabled>
                <img src={LockIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              <Typography sx={{ opacity: '60%' }} variant="h6">
                Şifre Değiştir
              </Typography>{' '}
            </Button>
          </Grid>
        </Stack>
        <Stack mt="4rem" direction="row" justifyContent="end" alignItems="center">
          <Grid item xs={4}>
            <Button variant="text" disableRipple>
              <IconButton size="small" disabled></IconButton>
              <Typography
                onClick={() => {
                  dispatch(LOGGED_OUT());
                }}
                sx={{
                  color: 'red',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  fontSize: '1.1rem'
                }}
                variant="body1"
              >
                Oturumu Kapat
              </Typography>
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileView;
