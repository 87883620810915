import { authApiService } from '../authApiService';
import * as dto from './dto';

const authApi = authApiService.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<dto.LoginResponseDto, dto.LoginRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/login`,
        method: 'POST',
        body
      })
    }),

    me: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/user/me`,
        method: 'GET'
      })
    }),
    getUserProfile: build.query<dto.UserProfileResponseDto, void>({
      query: () => ({
        url: `/backoffice-user/profile`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getBlockDrivers: build.query<dto.BlockDriverResponseDto[], void>({
      query: () => ({
        url: `/driver/block-drivers`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    updateUserProfile: build.mutation<void, dto.UpdateUserProfileRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/profile`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    userChangePassword: build.mutation<void, dto.ChangePasswordRequestDto>({
      query: (body) => ({
        url: `/backoffice-user/profile/password`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    blockDriver: build.mutation<void, dto.BlockDriverRequestDto>({
      query: (body) => ({
        url: `/driver/block-driver/${body.driverId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    unBlockDriver: build.mutation<void, dto.BlockDriverRequestDto>({
      query: (body) => ({
        url: `/driver/unblock-driver/${body.driverId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    individualDriverCreate: build.mutation<void, dto.individualDriverCreateRequestDto>({
      query: (body) => ({
        url: `/driver/register-driver-from-admin-panel`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    })
  })
});

export const {
  useLoginMutation,
  useLazyMeQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useUserChangePasswordMutation,
  useBlockDriverMutation,
  useUnBlockDriverMutation,
  useGetBlockDriversQuery,
  useIndividualDriverCreateMutation
} = authApi;
