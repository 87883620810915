import 'react-phone-input-2/lib/style.css';

import { useState } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import tr from 'react-phone-input-2/lang/tr.json';

export default function InputPhone({ ...props }: PhoneInputProps) {
  const [phoneValue, setPhoneValue] = useState(props.value || '');

  return (
    <PhoneInput
      country="tr"
      localization={tr}
      enableSearch
      enableAreaCodeStretch
      prefix="+"
      enableAreaCodes
      countryCodeEditable={false}
      preferredCountries={['tr']}
      disableSearchIcon
      searchPlaceholder="Ara"
      inputStyle={{
        width: '100%',
        height: '44px',
        maxWidth: '630px',
        color: 'black'
      }}
      onChange={(value, country, e, formattedValue) => {
        const formattedPhoneValue = value.startsWith('+') ? value : `+${value}`;
        setPhoneValue(formattedPhoneValue);
        if (props.onChange) {
          props.onChange(formattedPhoneValue, country, e, formattedValue);
        }
      }}
      value={phoneValue}
      {...props}
    />
  );
}
