import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { companySelectedSlice } from '@/components/CompanySelect/reducer/slice';
import { projcetSelectedSlice } from '@/components/ProjectSelect/reducer/slice';
import { dashboardProfileSlice } from '@/layouts/dashboard/profile/reducer/slice';
import { companyAddSlice } from '@/page/CompanyManagement/ApprovedCompanies/CompanyAdd/reducer/company-add-slice';
import { companyEditSlice } from '@/page/CompanyManagement/ApprovedCompanies/CompanyEdit/reducer/company-edit-slice';
import { companyApproveSlice } from '@/page/CompanyManagement/PendingCompanies/CompanyApprove/reducer/company-edit-slice';
import { authSlice } from '@/page/Login/reducer/auth-slice';
import { reportSlice } from '@/page/Reports/reducer/slice';
import { shipmentDetailSlice } from '@/page/ShipmentDetail/reducer/slice';
import { shipmentSlice } from '@/page/ShipmentManagement/edit/reducer/slice';
import { shipmentManagementSlice } from '@/page/ShipmentManagement/reducer/slice';

import { authApiService } from './api/authApiService';
import { backOfficeApiService } from './api/backOfficeApiService';
import { corporateApiService } from './api/corporateApiService';
import { generalApiService } from './api/generalApiService';
import { shipmentApiService } from './api/shipmentApiService';
const rootReducer = combineReducers({
  //api reducer'ları buraya ekleyin
  [authApiService.reducerPath]: authApiService.reducer,
  [backOfficeApiService.reducerPath]: backOfficeApiService.reducer,
  [corporateApiService.reducerPath]: corporateApiService.reducer,
  [generalApiService.reducerPath]: generalApiService.reducer,
  [shipmentApiService.reducerPath]: shipmentApiService.reducer,

  // Diğer reducer'ları buraya ekleyin (örneğin, slice'larınızı buraya ekleyin)

  [authSlice.name]: authSlice.reducer,
  [dashboardProfileSlice.name]: dashboardProfileSlice.reducer,
  [shipmentSlice.name]: shipmentSlice.reducer,
  [companySelectedSlice.name]: companySelectedSlice.reducer,
  [projcetSelectedSlice.name]: projcetSelectedSlice.reducer,
  [companyAddSlice.name]: companyAddSlice.reducer,
  [companyEditSlice.name]: companyEditSlice.reducer,
  [companyApproveSlice.name]: companyApproveSlice.reducer,
  [shipmentDetailSlice.name]: shipmentDetailSlice.reducer,
  [shipmentManagementSlice.name]: shipmentManagementSlice.reducer,
  [reportSlice.name]: reportSlice.reducer
});

// Store'u yapılandır
export const store = configureStore({
  reducer: rootReducer,

  /* {
   // Üretilen reducer'ı özel bir ana düzey kesite (slice) olarak ekleme
   [authApiService.reducerPath]: authApiService.reducer,
  } */
  // api middleware'i eklemek, önbellekleme, geçersiz kılma, anketleme ve
  // `rtk-query`'nin diğer faydalı özelliklerini etkinleştirir.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApiService.middleware,
      backOfficeApiService.middleware,
      generalApiService.middleware,
      corporateApiService.middleware,
      shipmentApiService.middleware
    )
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
