import InputPhone from './InputPhone';

export default function PhoneView({ value }: { value: string | undefined }) {
  return (
    <InputPhone
      inputProps={{
        required: true
      }}
      disabled
      inputStyle={{
        border: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        width: '100%',
        fontWeight: 700,
        fontSize: 16,
        paddingLeft: 35,
        cursor: 'default',
        pointerEvents: 'none',
        color: 'black'
      }}
      searchStyle={{
        border: 0,
        paddingLeft: 0
      }}
      containerStyle={{
        border: 0,
        paddingLeft: 0,
        marginLeft: -10
      }}
      buttonStyle={{
        border: 0,
        borderColor: 'transparent',
        borderRadius: 0,
        backgroundColor: 'transparent',
        paddingLeft: 0
      }}
      disableSearchIcon
      disableDropdown
      value={value}
    />
  );
}
